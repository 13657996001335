@import "../mixins/screen";

/*
    OVERFLOW

      Media Query:
        sm_ = small and up
        md_  = medium and up
        lg_  = large and up
        xl_  = extra large and up
   */


	 .overflow-visible { overflow: visible !important; }
	 .overflow-hidden { overflow: hidden !important; }
	 .overflow-scroll { overflow: scroll !important; }
	 .overflow-auto { overflow: auto !important; }

	 .overflow-x-visible { overflow-x: visible !important; }
	 .overflow-x-hidden { overflow-x: hidden !important; }
	 .overflow-x-scroll { overflow-x: scroll !important; }
	 .overflow-x-auto { overflow-x: auto !important; }

	 .overflow-y-visible { overflow-y: visible !important; }
	 .overflow-y-hidden { overflow-y: hidden !important; }
	 .overflow-y-scroll { overflow-y: scroll !important; }
	 .overflow-y-auto { overflow-y: auto !important; }


	 @media sm {
		 .sm_overflow-visible { overflow: visible !important; }
		 .sm_overflow-hidden { overflow: hidden !important; }
		 .sm_overflow-scroll { overflow: scroll !important; }
		 .sm_overflow-auto { overflow: auto !important; }

		 .sm_overflow-x-visible { overflow-x: visible !important; }
		 .sm_overflow-x-hidden { overflow-x: hidden !important; }
		 .sm_overflow-x-scroll { overflow-x: scroll !important; }
		 .sm_overflow-x-auto { overflow-x: auto !important; }

		 .sm_overflow-y-visible { overflow-y: visible !important; }
		 .sm_overflow-y-hidden { overflow-y: hidden !important; }
		 .sm_overflow-y-scroll { overflow-y: scroll !important; }
		 .sm_overflow-y-auto { overflow-y: auto !important; }
	 }

	 @media md {
		 .md_overflow-visible { overflow: visible !important; }
		 .md_overflow-hidden { overflow: hidden !important; }
		 .md_overflow-scroll { overflow: scroll !important; }
		 .md_overflow-auto { overflow: auto !important; }

		 .md_overflow-x-visible { overflow-x: visible !important; }
		 .md_overflow-x-hidden { overflow-x: hidden !important; }
		 .md_overflow-x-scroll { overflow-x: scroll !important; }
		 .md_overflow-x-auto { overflow-x: auto !important; }

		 .md_overflow-y-visible { overflow-y: visible !important; }
		 .md_overflow-y-hidden { overflow-y: hidden !important; }
		 .md_overflow-y-scroll { overflow-y: scroll !important; }
		 .md_overflow-y-auto { overflow-y: auto !important; }
	 }

	 @media lg {
		 .lg_overflow-visible { overflow: visible !important; }
		 .lg_overflow-hidden { overflow: hidden !important; }
		 .lg_overflow-scroll { overflow: scroll !important; }
		 .lg_overflow-auto { overflow: auto !important; }

		 .lg_overflow-x-visible { overflow-x: visible !important; }
		 .lg_overflow-x-hidden { overflow-x: hidden !important; }
		 .lg_overflow-x-scroll { overflow-x: scroll !important; }
		 .lg_overflow-x-auto { overflow-x: auto !important; }

		 .lg_loverflow-y-visible { overflow-y: visible !important; }
		 .lg_overflow-y-hidden { overflow-y: hidden !important; }
		 .lg_overflow-y-scroll { overflow-y: scroll !important; }
		 .lg_overflow-y-auto { overflow-y: auto !important; }
	 }

	 @media xl {
		 .xl_overflow-visible { overflow: visible !important; }
		 .xl_overflow-hidden { overflow: hidden !important; }
		 .xl_overflow-scroll { overflow: scroll !important; }
		 .xl_overflow-auto { overflow: auto !important; }

		 .xl_overflow-x-visible { overflow-x: visible !important; }
		 .xl_overflow-x-hidden { overflow-x: hidden !important; }
		 .xl_overflow-x-scroll { overflow-x: scroll !important; }
		 .xl_overflow-x-auto { overflow-x: auto !important; }

		 .xl_loverflow-y-visible { overflow-y: visible !important; }
		 .xl_overflow-y-hidden { overflow-y: hidden !important; }
		 .xl_overflow-y-scroll { overflow-y: scroll !important; }
		 .xl_overflow-y-auto { overflow-y: auto !important; }
	 }

   @media xxl {
    .xxl_overflow-visible { overflow: visible !important; }
    .xxl_overflow-hidden { overflow: hidden !important; }
    .xxl_overflow-scroll { overflow: scroll !important; }
    .xxl_overflow-auto { overflow: auto !important; }

    .xxl_overflow-x-visible { overflow-x: visible !important; }
    .xxl_overflow-x-hidden { overflow-x: hidden !important; }
    .xxl_overflow-x-scroll { overflow-x: scroll !important; }
    .xxl_overflow-x-auto { overflow-x: auto !important; }

    .xxl_loverflow-y-visible { overflow-y: visible !important; }
    .xxl_overflow-y-hidden { overflow-y: hidden !important; }
    .xxl_overflow-y-scroll { overflow-y: scroll !important; }
    .xxl_overflow-y-auto { overflow-y: auto !important; }
  }
