@import "@dreambigger/design-system/src/styles/variables/colors.scss";

// *********************************************
// UNIVERSAL OVERRIDES
// *********************************************
.ant-notification-notice-close {
  color: $gray-6;
}

.ant-slider-rail {
  background-color: $gray-5;
  border-radius: 40px;
}

.ant-input.ant-input.ant-input {
  font-size: 18px;
}

.ant-input {
  font-size: 16px;
}

// The stutter is intentional. This is to increase the specificity of the selector.
.ant-btn-primary.ant-btn-primary.ant-btn-primary.ant-btn-primary.ant-btn-primary {
  &:hover {
    background-color: #347a71;
  }
}

// *********************************************
// BORDERLESS INPUT --- "ao-bl-input"
// *********************************************
.ao-bl-input.ant-input {
  background-color: transparent;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  font-size: 18px;
  padding: 0px !important;
  color: $gray-8;
  border-radius: 0px;
  min-height: 31px !important;
}

.ao-bl-input.ant-input-number {
  background-color: transparent;
  border-top: transparent !important;
  border-left: transparent !important;
  border-right: transparent !important;
  font-size: 18px;
  padding: 0px;
  color: $gray-8;
  border-radius: 0px;
  min-height: 31px !important;
}

.ao-bl-input.ant-input-number-affix-wrapper {
  background-color: inherit !important;
  border-top: inherit !important;
  border-left: inherit !important;
  border-right: inherit !important;
  font-size: 18px !important;
  color: $gray-8 !important;
  padding: 0px !important;
}

.ao-bl-input.ant-input-number-handler-wrap {
  border-left: transparent !important;
  background-color: white !important;
}

.ao-bl-input.ant-input {
  background-color: transparent !important;
}

// *********************************************
// BORDERLESS SELECT --- "ao-bl-select"
// *********************************************
.ao-bl-select > .ant-select-selector {
  background-color: transparent !important;
  border-top: transparent !important;
  border-left: transparent !important;
  border-right: transparent !important;
  font-size: 18px !important;
  padding: 0px !important;
  color: $gray-8 !important;
  border-radius: 0px !important;
  min-height: 30px !important;
  cursor: pointer !important;
}

.ao-bl-select > .ant-select-selector > .ant-select-selection-search {
  left: 0px !important;
  cursor: pointer !important;
}

.ao-bl-select
  > .ant-select-selector
  > .ant-select-selection-search
  > .ant-select-selection-search-input {
  background-color: transparent !important;
  border-top: transparent !important;
  border-left: transparent !important;
  border-right: transparent !important;
  font-size: 18px !important;
  padding: 0px !important;
  color: $gray-8 !important;
  border-radius: 0px !important;
  min-height: 30px !important;
  cursor: pointer !important;
}

.ao-bl-select
  > .ant-select-selector
  > .ant-select-selection-search
  > .ant-select-selection-search-input
  > .ant-input {
  background-color: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
  cursor: pointer !important;
}

.ao-bl-input > .ant-input-number-input-wrap > .ant-input-number-input {
  padding: 0px !important;
}

// *********************************************
// BORDERLESS PICKER --- "ao-bl-picket"
// *********************************************
.ao-bl-picker.ant-picker {
  background-color: transparent;
  border-top: transparent !important;
  border-left: transparent !important;
  border-right: transparent !important;
  padding: 0px;
  color: $gray-8;
  border-radius: 0px;
  min-height: 31px !important;
}

// Using # for now because of time limitations in finding the correct selector.
// TODO: Replace with a better selector.
.ao-bl-picker > .ant-picker-input > #birthday {
  font-size: 18px !important;
  color: $gray-8;
}

.ao-bl-picker > .ant-picker-input > #startDate,
#endDate,
#birthday,
#coborrowerBirthday,
#coborrowerStartDate,
#jointApplicantBirthday,
#birthDate,
#masterSignerBirthday,
#authorizedSignerBirthday,
#existingMemberBirthday {
  font-size: 18px !important;
  color: $gray-8;
}

.ao-bl-picker > .ant-picker-input > .ant-picker-suffix {
  font-size: 18px !important;
}

// *********************************************
// LARGE RADIO BUTTONS --- "ao-lg-radio"
// *********************************************
.ao-lg-radio.ant-radio {
  margin-right: 4px;
}
.ao-lg-radio > .ant-radio-checked {
  transform: scale(1.2) !important;
}

// *********************************************
// LARGE CHECKBOX --- "ao-lg-checkbox"
// *********************************************
.ao-lg-checkbox > .ant-checkbox > .ant-checkbox-inner,
.ao-lg-checkbox > .ant-checkbox-input {
  transform: scale(1.2);
}
.ao-vrt-checkbox {
  display: flex;
  flex-direction: column;
}
.ant-checkbox-group > .ant-checkbox-group-item {
  padding-top: 12px;
  font-weight: normal;
}

.ant-select-selector:focus {
  box-shadow: none !important;
}

.ant-input:focus {
  box-shadow: none !important;
}

// *********************************************
// SLIDER INPUT --- "ao-slider-input"
// *********************************************
.ao-slider-input > .ant-input-number-input-wrap > .ant-input-number-input {
  height: auto !important;
  font-weight: 600;
  font-size: 60px !important;
}

// *********************************************
// COLLAPSE PANEL--- "ao-collapse-panel"
// *********************************************
.ao-collapse-panel > .ant-collapse-header {
  padding: 6px 0px !important;
  align-items: center !important;
}
.ao-collapse-panel > .ant-collapse-header > h3 {
  margin: 0px 4px 0px 4px !important;
}
