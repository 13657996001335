$white: #ffffff;
$black: #000000;

$gray-1: #f9f9f9;
$gray-2: #f6f6f6;
$gray-3: #f5f5f5;
$gray-4: #f0f0f0;
$gray-5: #d9d9d9;
$gray-6: #bfbfbf;
$gray-7: #8c8c8c;
$gray-8: #595959;
$gray-9: #262626;

$teal-6: #52c1b2;
$teal-7: #49ada0;

$green-5: #8dc756;
$green-6: #52c41a;

//for errors
$red-1: #cc0000;
