@import "../mixins/screen";

/* 1. Fix for Chrome 44 bug.
 * https://code.google.com/p/chromium/issues/detail?id=506893 */
.flex-auto {
	flex: 1 1 auto !important;
	min-width: 0 !important; /* 1 */
	min-height: 0 !important; /* 1 */
}

.flex-none {
	flex: none !important;
}

.flex-column {
	flex-direction: column !important;
}
.flex-row {
	flex-direction: row !important;
}
.flex-wrap {
	flex-wrap: wrap !important;
}
.flex-nowrap {
	flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}
.flex-column-reverse {
	flex-direction: column-reverse !important;
}
.flex-row-reverse {
	flex-direction: row-reverse !important;
}

.items-start {
	align-items: flex-start !important;
}
.items-end {
	align-items: flex-end !important;
}
.items-center {
	align-items: center !important;
}
.items-baseline {
	align-items: baseline !important;
}
.items-stretch {
	align-items: stretch !important;
}

.self-start {
	align-self: flex-start !important;
}
.self-end {
	align-self: flex-end !important;
}
.self-center {
	align-self: center !important;
}
.self-baseline {
	align-self: baseline !important;
}
.self-stretch {
	align-self: stretch !important;
}

.justify-start {
	justify-content: flex-start !important;
}
.justify-end {
	justify-content: flex-end !important;
}
.justify-center {
	justify-content: center !important;
}
.justify-between {
	justify-content: space-between !important;
}
.justify-around {
	justify-content: space-around !important;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

.content-start {
	align-content: flex-start !important;
}
.content-end {
	align-content: flex-end !important;
}
.content-center {
	align-content: center !important;
}
.content-between {
	align-content: space-between !important;
}
.content-around {
	align-content: space-around !important;
}
.content-stretch {
	align-content: stretch !important;
}

$order: (
	"0": 0,
	"1": 1,
	"2": 2,
	"3": 3,
	"4": 4,
	"5": 5,
	"6": 6,
	"7": 7,
	"8": 8,
);

@each $name, $number in $order {
	.order-#{$name} {
		order: $number !important;
	}
}

.order-last {
	order: 99999 !important;
}

$flex: (
	"0": 0,
	"1": 1,
	"2": 2,
	"3": 3
);

@each $name, $number in $flex {
	.flex-grow-#{$name} {
		flex-grow: $number !important;
	}
	.flex-shrink-#{$name} {
		flex-shrink: $number !important;
	}
	.flex-basis-#{$name} {
		flex-basis: $number !important;
	}
}

@include sm {
	.sm_flex-auto {
		flex: 1 1 auto !important;
		min-width: 0 !important; /* 1 */
		min-height: 0 !important; /* 1 */
	}

	.sm_flex-none {
		flex: none !important;
	}

	.sm_flex-column {
		flex-direction: column !important;
	}
	.sm_flex-row {
		flex-direction: row !important;
	}
	.sm_flex-wrap {
		flex-wrap: wrap !important;
	}
	.sm_flex-nowrap {
		flex-wrap: nowrap !important;
	}
	.sm_flex-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.sm_flex-column-reverse {
		flex-direction: column-reverse !important;
	}
	.sm_flex-row-reverse {
		flex-direction: row-reverse !important;
	}

	.sm_items-start {
		align-items: flex-start !important;
	}
	.sm_items-end {
		align-items: flex-end !important;
	}
	.sm_items-center {
		align-items: center !important;
	}
	.sm_items-baseline {
		align-items: baseline !important;
	}
	.sm_items-stretch {
		align-items: stretch !important;
	}

	.sm_self-start {
		align-self: flex-start !important;
	}
	.sm_self-end {
		align-self: flex-end !important;
	}
	.sm_self-center {
		align-self: center !important;
	}
	.sm_self-baseline {
		align-self: baseline !important;
	}
	.sm_self-stretch {
		align-self: stretch !important;
	}

	.sm_justify-start {
		justify-content: flex-start !important;
	}
	.sm_justify-end {
		justify-content: flex-end !important;
	}
	.sm_justify-center {
		justify-content: center !important;
	}
	.sm_justify-between {
		justify-content: space-between !important;
	}
	.sm_justify-around {
		justify-content: space-around !important;
	}

	.sm_content-start {
		align-content: flex-start !important;
	}
	.sm_content-end {
		align-content: flex-end !important;
	}
	.sm_content-center {
		align-content: center !important;
	}
	.sm_content-between {
		align-content: space-between !important;
	}
	.sm_content-around {
		align-content: space-around !important;
	}
	.sm_content-stretch {
		align-content: stretch !important;
	}

	@each $name, $number in $order {
		.sm_order-#{$name} {
			order: $number !important;
		}
	}
	.sm_order-last {
		order: 99999 !important;
	}

	@each $name, $number in $flex {
		.sm_flex-grow-#{$name} {
			flex-grow: $number !important;
		}
		.sm_flex-shrink-#{$name} {
			flex-shrink: $number !important;
		}
		.sm_flex-basis-#{$name} {
			flex-basis: $number !important;
		}
	}

	.sm_flex-shrink-0 {
		flex-shrink: 0 !important;
	}
	.sm_flex-shrink-1 {
		flex-shrink: 1 !important;
	}
}

@include md {
	.md_flex-auto {
		flex: 1 1 auto !important;
		min-width: 0 !important; /* 1 */
		min-height: 0 !important; /* 1 */
	}
	.md_flex-none {
		flex: none !important;
	}

	.md_flex-column {
		flex-direction: column !important;
	}
	.md_flex-row {
		flex-direction: row !important;
	}
	.md_flex-wrap {
		flex-wrap: wrap !important;
	}
	.md_flex-nowrap {
		flex-wrap: nowrap !important;
	}
	.md_flex-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.md_flex-column-reverse {
		flex-direction: column-reverse !important;
	}
	.md_flex-row-reverse {
		flex-direction: row-reverse !important;
	}

	.md_items-start {
		align-items: flex-start !important;
	}
	.md_items-end {
		align-items: flex-end !important;
	}
	.md_items-center {
		align-items: center !important;
	}
	.md_items-baseline {
		align-items: baseline !important;
	}
	.md_items-stretch {
		align-items: stretch !important;
	}

	.md_self-start {
		align-self: flex-start !important;
	}
	.md_self-end {
		align-self: flex-end !important;
	}
	.md_self-center {
		align-self: center !important;
	}
	.md_self-baseline {
		align-self: baseline !important;
	}
	.md_self-stretch {
		align-self: stretch !important;
	}

	.md_justify-start {
		justify-content: flex-start !important;
	}
	.md_justify-end {
		justify-content: flex-end !important;
	}
	.md_justify-center {
		justify-content: center !important;
	}
	.md_justify-between {
		justify-content: space-between !important;
	}
	.md_justify-around {
		justify-content: space-around !important;
	}

	.md_content-start {
		align-content: flex-start !important;
	}
	.md_content-end {
		align-content: flex-end !important;
	}
	.md_content-center {
		align-content: center !important;
	}
	.md_content-between {
		align-content: space-between !important;
	}
	.md_content-around {
		align-content: space-around !important;
	}
	.md_content-stretch {
		align-content: stretch !important;
	}

	@each $name, $number in $order {
		.md_order-#{$name} {
			order: $number !important;
		}
	}
	.md_order-last {
		order: 99999 !important;
	}

	@each $name, $number in $flex {
		.md_flex-grow-#{$name} {
			flex-grow: $number !important;
		}
		.md_flex-shrink-#{$name} {
			flex-shrink: $number !important;
		}
		.md_flex-basis-#{$name} {
			flex-basis: $number !important;
		}
	}
}

@include lg {
	.lg_flex-auto {
		flex: 1 1 auto !important;
		min-width: 0 !important; /* 1 */
		min-height: 0 !important; /* 1 */
	}

	.lg_flex-none {
		flex: none !important;
	}

	.lg_flex-column {
		flex-direction: column !important;
	}
	.lg_flex-row {
		flex-direction: row !important;
	}
	.lg_flex-wrap {
		flex-wrap: wrap !important;
	}
	.lg_flex-nowrap {
		flex-wrap: nowrap !important;
	}
	.lg_flex-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.lg_flex-column-reverse {
		flex-direction: column-reverse !important;
	}
	.lg_flex-row-reverse {
		flex-direction: row-reverse !important;
	}

	.lg_items-start {
		align-items: flex-start !important;
	}
	.lg_items-end {
		align-items: flex-end !important;
	}
	.lg_items-center {
		align-items: center !important;
	}
	.lg_items-baseline {
		align-items: baseline !important;
	}
	.lg_items-stretch {
		align-items: stretch !important;
	}

	.lg_self-start {
		align-self: flex-start !important;
	}
	.lg_self-end {
		align-self: flex-end !important;
	}
	.lg_self-center {
		align-self: center !important;
	}
	.lg_self-baseline {
		align-self: baseline !important;
	}
	.lg_self-stretch {
		align-self: stretch !important;
	}

	.lg_justify-start {
		justify-content: flex-start !important;
	}
	.lg_justify-end {
		justify-content: flex-end !important;
	}
	.lg_justify-center {
		justify-content: center !important;
	}
	.lg_justify-between {
		justify-content: space-between !important;
	}
	.lg_justify-around {
		justify-content: space-around !important;
	}

	.lg_content-start {
		align-content: flex-start !important;
	}
	.lg_content-end {
		align-content: flex-end !important;
	}
	.lg_content-center {
		align-content: center !important;
	}
	.lg_content-between {
		align-content: space-between !important;
	}
	.lg_content-around {
		align-content: space-around !important;
	}
	.lg_content-stretch {
		align-content: stretch !important;
	}

	@each $name, $number in $order {
		.lg_order-#{$name} {
			order: $number !important;
		}
	}
	.lg_order-last {
		order: 99999 !important;
	}

	@each $name, $number in $flex {
		.lg_flex-grow-#{$name} {
			flex-grow: $number !important;
		}
		.lg_flex-shrink-#{$name} {
			flex-shrink: $number !important;
		}
		.lg_flex-basis-#{$name} {
			flex-basis: $number !important;
		}
	}
}

@include xl {
	.xl_flex-auto {
		flex: 1 1 auto !important;
		min-width: 0 !important; /* 1 */
		min-height: 0 !important; /* 1 */
	}

	.xl_flex-none {
		flex: none !important;
	}

	.xl_flex-column {
		flex-direction: column !important;
	}
	.xl_flex-row {
		flex-direction: row !important;
	}
	.xl_flex-wrap {
		flex-wrap: wrap !important;
	}
	.xl_flex-nowrap {
		flex-wrap: nowrap !important;
	}
	.xl_flex-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.xl_flex-column-reverse {
		flex-direction: column-reverse !important;
	}
	.xl_flex-row-reverse {
		flex-direction: row-reverse !important;
	}

	.xl_items-start {
		align-items: flex-start !important;
	}
	.xl_items-end {
		align-items: flex-end !important;
	}
	.xl_items-center {
		align-items: center !important;
	}
	.xl_items-baseline {
		align-items: baseline !important;
	}
	.xl_items-stretch {
		align-items: stretch !important;
	}

	.xl_self-start {
		align-self: flex-start !important;
	}
	.xl_self-end {
		align-self: flex-end !important;
	}
	.xl_self-center {
		align-self: center !important;
	}
	.xl_self-baseline {
		align-self: baseline !important;
	}
	.xl_self-stretch {
		align-self: stretch !important;
	}

	.xl_justify-start {
		justify-content: flex-start !important;
	}
	.xl_justify-end {
		justify-content: flex-end !important;
	}
	.xl_justify-center {
		justify-content: center !important;
	}
	.xl_justify-between {
		justify-content: space-between !important;
	}
	.xl_justify-around {
		justify-content: space-around !important;
	}

	.xl_content-start {
		align-content: flex-start !important;
	}
	.xl_content-end {
		align-content: flex-end !important;
	}
	.xl_content-center {
		align-content: center !important;
	}
	.xl_content-between {
		align-content: space-between !important;
	}
	.xl_content-around {
		align-content: space-around !important;
	}
	.xl_content-stretch {
		align-content: stretch !important;
	}

	@each $name, $number in $order {
		.xl_order-#{$name} {
			order: $number !important;
		}
	}
	.xl_order-last {
		order: 99999 !important;
	}

	@each $name, $number in $flex {
		.xl_flex-grow-#{$name} {
			flex-grow: $number !important;
		}
		.xl_flex-shrink-#{$name} {
			flex-shrink: $number !important;
		}
		.xl_flex-basis-#{$name} {
			flex-basis: $number !important;
		}
	}
}

@include xxl {
	.xxl_flex-auto {
		flex: 1 1 auto !important;
		min-width: 0 !important; /* 1 */
		min-height: 0 !important; /* 1 */
	}

	.xxl_flex-none {
		flex: none !important;
	}

	.xxl_flex-column {
		flex-direction: column !important;
	}
	.xxl_flex-row {
		flex-direction: row !important;
	}
	.xxl_flex-wrap {
		flex-wrap: wrap !important;
	}
	.xxl_flex-nowrap {
		flex-wrap: nowrap !important;
	}
	.xxl_flex-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.xxl_flex-column-reverse {
		flex-direction: column-reverse !important;
	}
	.xxl_flex-row-reverse {
		flex-direction: row-reverse !important;
	}

	.xxl_items-start {
		align-items: flex-start !important;
	}
	.xxl_items-end {
		align-items: flex-end !important;
	}
	.xxl_items-center {
		align-items: center !important;
	}
	.xxl_items-baseline {
		align-items: baseline !important;
	}
	.xxl_items-stretch {
		align-items: stretch !important;
	}

	.xxl_self-start {
		align-self: flex-start !important;
	}
	.xxl_self-end {
		align-self: flex-end !important;
	}
	.xxl_self-center {
		align-self: center !important;
	}
	.xxl_self-baseline {
		align-self: baseline !important;
	}
	.xxl_self-stretch {
		align-self: stretch !important;
	}

	.xxl_justify-start {
		justify-content: flex-start !important;
	}
	.xxl_justify-end {
		justify-content: flex-end !important;
	}
	.xxl_justify-center {
		justify-content: center !important;
	}
	.xxl_justify-between {
		justify-content: space-between !important;
	}
	.xxl_justify-around {
		justify-content: space-around !important;
	}

	.xxl_content-start {
		align-content: flex-start !important;
	}
	.xxl_content-end {
		align-content: flex-end !important;
	}
	.xxl_content-center {
		align-content: center !important;
	}
	.xxl_content-between {
		align-content: space-between !important;
	}
	.xxl_content-around {
		align-content: space-around !important;
	}
	.xxl_content-stretch {
		align-content: stretch !important;
	}

	@each $name, $number in $order {
		.xxl_order-#{$name} {
			order: $number !important;
		}
	}
	.xxl_order-last {
		order: 99999 !important;
	}

	@each $name, $number in $flex {
		.xxl_flex-grow-#{$name} {
			flex-grow: $number !important;
		}
		.xxl_flex-shrink-#{$name} {
			flex-shrink: $number !important;
		}
		.xxl_flex-basis-#{$name} {
			flex-basis: $number !important;
		}
	}
}
