@import "../mixins/screen";

/*
   POSITIONING
   Media Query:
     sm_ = small and up
     md_  = medium and up
     lg_  = large and up
     xl_  = extra large and up
*/

.static {
	position: static !important;
}
.relative {
	position: relative !important;
}
.absolute {
	position: absolute !important;
}
.fixed {
	position: fixed !important;
}
.sticky {
	position: sticky !important;
	position: -webkit-sticky !important;
}

@include sm {
	.sm_static {
		position: static !important;
	}
	.sm_relative {
		position: relative !important;
	}
	.sm_absolute {
		position: absolute !important;
	}
	.sm_fixed {
		position: fixed !important;
	}
	.sm_sticky {
		position: sticky !important;
		position: -webkit-sticky !important;
	}
}

@include md {
	.md_static {
		position: static !important;
	}
	.md_relative {
		position: relative !important;
	}
	.md_absolute {
		position: absolute !important;
	}
	.md_fixed {
		position: fixed !important;
	}
	.md_sticky {
		position: sticky !important;
		position: -webkit-sticky !important;
	}
}

@include lg {
	.lg_static {
		position: static !important;
	}
	.lg_relative {
		position: relative !important;
	}
	.lg_absolute {
		position: absolute !important;
	}
	.lg_fixed {
		position: fixed !important;
	}
	.lg_sticky {
		position: sticky !important;
		position: -webkit-sticky !important;
	}
}

@include xl {
	.xl_static {
		position: static !important;
	}
	.xl_relative {
		position: relative !important;
	}
	.xl_absolute {
		position: absolute !important;
	}
	.xl_fixed {
		position: fixed !important;
	}
	.xl_sticky {
		position: sticky !important;
		position: -webkit-sticky !important;
	}
}

@include xxl {
	.xxl_static {
		position: static !important;
	}
	.xxl_relative {
		position: relative !important;
	}
	.xxl_absolute {
		position: absolute !important;
	}
	.xxl_fixed {
		position: fixed !important;
	}
	.xxl_sticky {
		position: sticky !important;
		position: -webkit-sticky !important;
	}
}
