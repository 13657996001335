$base-font-family: "proxima-nova", Helvetica, sans-serif !important;

$fontSize-1: 10px;
$fontSize-2: 12px;
$fontSize-3: 14px; // Default text size
$fontSize-4: 16px; // h4 size
$fontSize-5: 20px; // h4 size
$fontSize-6: 24px; // h3 size
$fontSize-7: 30px; // h2 size
$fontSize-8: 38px; // h1 size
$fontSize-9: 50px; // Extra large size
$fontSize-10: 60px; // Massive size
$font-size-11: 70px; 
$font-size-12: 80px;
$font-size-13: 90px;
$font-size-14: 100px;

$lineheight-1: 1;
$lineheight-2: 1.25;
$lineheight-3: 1.375;
$lineheight-4: 1.5;
$lineheight-5: 1.625;
$lineheight-6: 2;

$fontWeight-normal: 400;
$fontWeight-bold: 600;
$fontWeight-thick: 900;
