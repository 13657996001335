@import "../variables/colors";
@import "../variables/spacing";
@import "../mixins/screen";

/*
    BOX-SHADOW
    Base:
      box-shadow = s
    Modifiers:
      1 - Subtle
      2 - Very Light
      3 - Light
      4 - Medium
      5 - Heavy
      6 - Very Heavy

      Media Query:
        sm_ = small and up
        md_  = medium and up
        lg_  = large and up
        xl_  = extra large and up
        xxl_ = massive and up
   */

// Normal Shadow
.s-1 {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px !important;
}

.s-2 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important;
}

.s-3 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
}

.s-4 {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}

.s-5 {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
}

.s-6 {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px !important;
}

.s-7 {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px !important;
}

// Shadow Right
.sr-1 {
  box-shadow: rgba(0, 0, 0, 0.2) 8px 0px 10px -3.5px !important;
}

.sr-2 {
  box-shadow: rgba(0, 0, 0, 0.2) 9px 0px 10px -3.5px !important;
}

.sr-3 {
  box-shadow: rgba(0, 0, 0, 0.22) 11px 0px 10px -3.5px !important;
}

.sr-4 {
  box-shadow: rgba(0, 0, 0, 0.22) 15px 0px 10px -3.9px !important;
}

.sr-5 {
  box-shadow: rgba(0, 0, 0, 0.23) 22px 0px 10px -4.7px !important;
}

.sr-6 {
  box-shadow: rgba(0, 0, 0, 0.23) 26px 0px 10px -6px !important;
}

.sr-7 {
  box-shadow: rgba(0, 0, 0, 0.23) 45px 0px 12px -11px !important;
}

@include sm {
  // Normal Shadow
  .sm_s-1 {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px !important;
  }
  
  .sm_s-2 {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important;
  }
  
  .sm_s-3 {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  }
  
  .sm_s-4 {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
  }
  
  .sm_s-5 {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
  }
  
  .sm_s-6 {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px !important;
  }
  
  .sm_s-7 {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px !important;
  }

  // Shadow Right
  .sm_sr-1 {
    box-shadow: rgba(0, 0, 0, 0.2) 8px 0px 10px -3.5px !important;
  }
  
  .sm_sr-2 {
    box-shadow: rgba(0, 0, 0, 0.2) 9px 0px 10px -3.5px !important;
  }
  
  .sm_sr-3 {
    box-shadow: rgba(0, 0, 0, 0.22) 11px 0px 10px -3.5px !important;
  }
  
  .sm_sr-4 {
    box-shadow: rgba(0, 0, 0, 0.22) 15px 0px 10px -3.9px !important;
  }
  
  .sm_sr-5 {
    box-shadow: rgba(0, 0, 0, 0.23) 22px 0px 10px -4.7px !important;
  }
  
  .sm_sr-6 {
    box-shadow: rgba(0, 0, 0, 0.23) 26px 0px 10px -6px !important;
  }
  
  .sm_sr-7 {
    box-shadow: rgba(0, 0, 0, 0.23) 45px 0px 12px -11px !important;
  }
}

@include md {
  // Normal Shadow
  .md_s-1 {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px !important;
  }
  
  .md_s-2 {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important;
  }
  
  .md_s-3 {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  }
  
  .md_s-4 {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
  }
  
  .md_s-5 {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
  }
  
  .md_s-6 {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px !important;
  }
  
  .md_s-7 {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px !important;
  }

  // Shadow Right
  .md_sr-1 {
    box-shadow: rgba(0, 0, 0, 0.2) 8px 0px 10px -3.5px !important;
  }
  
  .md_sr-2 {
    box-shadow: rgba(0, 0, 0, 0.2) 9px 0px 10px -3.5px !important;
  }
  
  .md_sr-3 {
    box-shadow: rgba(0, 0, 0, 0.22) 11px 0px 10px -3.5px !important;
  }
  
  .md_sr-4 {
    box-shadow: rgba(0, 0, 0, 0.22) 15px 0px 10px -3.9px !important;
  }
  
  .md_sr-5 {
    box-shadow: rgba(0, 0, 0, 0.23) 22px 0px 10px -4.7px !important;
  }
  
  .md_sr-6 {
    box-shadow: rgba(0, 0, 0, 0.23) 26px 0px 10px -6px !important;
  }
  
  .md_sr-7 {
    box-shadow: rgba(0, 0, 0, 0.23) 45px 0px 12px -11px !important;
  }
}

@include lg {
  // Normal Shadow
  .lg_s-1 {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px !important;
  }
  
  .lg_s-2 {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important;
  }
  
  .lg_s-3 {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  }
  
  .lg_s-4 {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
  }
  
  .lg_s-5 {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
  }
  
  .lg_s-6 {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px !important;
  }
  
  .lg_s-7 {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px !important;
  }
  // Shadow Right
  .lg_sr-1 {
    box-shadow: rgba(0, 0, 0, 0.2) 8px 0px 10px -3.5px !important;
  }
  
  .lg_sr-2 {
    box-shadow: rgba(0, 0, 0, 0.2) 9px 0px 10px -3.5px !important;
  }
  
  .lg_sr-3 {
    box-shadow: rgba(0, 0, 0, 0.22) 11px 0px 10px -3.5px !important;
  }
  
  .lg_sr-4 {
    box-shadow: rgba(0, 0, 0, 0.22) 15px 0px 10px -3.9px !important;
  }
  
  .lg_sr-5 {
    box-shadow: rgba(0, 0, 0, 0.23) 22px 0px 10px -4.7px !important;
  }
  
  .lg_sr-6 {
    box-shadow: rgba(0, 0, 0, 0.23) 26px 0px 10px -6px !important;
  }
  
  .lg_sr-7 {
    box-shadow: rgba(0, 0, 0, 0.23) 45px 0px 12px -11px !important;
  }
}

@include xl {
  // Normal Shadow
  .xl_s-1 {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px !important;
  }
  
  .xl_s-2 {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important;
  }
  
  .xl_s-3 {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  }
  
  .xl_s-4 {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
  }
  
  .xl_s-5 {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
  }
  
  .xl_s-6 {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px !important;
  }
  
  .xl_s-7 {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px !important;
  }

  // Shadow Right
  .xl_sr-1 {
    box-shadow: rgba(0, 0, 0, 0.2) 8px 0px 10px -3.5px !important;
  }
  
  .xl_sr-2 {
    box-shadow: rgba(0, 0, 0, 0.2) 9px 0px 10px -3.5px !important;
  }
  
  .xl_sr-3 {
    box-shadow: rgba(0, 0, 0, 0.22) 11px 0px 10px -3.5px !important;
  }
  
  .xl_sr-4 {
    box-shadow: rgba(0, 0, 0, 0.22) 15px 0px 10px -3.9px !important;
  }
  
  .xl_sr-5 {
    box-shadow: rgba(0, 0, 0, 0.23) 22px 0px 10px -4.7px !important;
  }
  
  .xl_sr-6 {
    box-shadow: rgba(0, 0, 0, 0.23) 26px 0px 10px -6px !important;
  }
  
  .xl_sr-7 {
    box-shadow: rgba(0, 0, 0, 0.23) 45px 0px 12px -11px !important;
  }
}

@include xxl {
  // Normal Shadow
  .xxl_s-1 {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px !important;
  }
  
  .xxl_s-2 {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important;
  }
  
  .xxl_s-3 {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  }
  
  .xxl_s-4 {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
  }
  
  .xxl_s-5 {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
  }
  
  .xxl_s-6 {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px !important;
  }
  
  .xxl_s-7 {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px !important;
  }

  // Shadow Right
  .xxl_sr-1 {
    box-shadow: rgba(0, 0, 0, 0.2) 8px 0px 10px -3.5px !important;
  }
  
  .xxl_sr-2 {
    box-shadow: rgba(0, 0, 0, 0.2) 9px 0px 10px -3.5px !important;
  }
  
  .xxl_sr-3 {
    box-shadow: rgba(0, 0, 0, 0.22) 11px 0px 10px -3.5px !important;
  }
  
  .xxl_sr-4 {
    box-shadow: rgba(0, 0, 0, 0.22) 15px 0px 10px -3.9px !important;
  }
  
  .xxl_sr-5 {
    box-shadow: rgba(0, 0, 0, 0.23) 22px 0px 10px -4.7px !important;
  }
  
  .xxl_sr-6 {
    box-shadow: rgba(0, 0, 0, 0.23) 26px 0px 10px -6px !important;
  }
  
  .xxl_sr-7 {
    box-shadow: rgba(0, 0, 0, 0.23) 45px 0px 12px -11px !important;
  }
}