@import "../variables/colors";
@import "../variables/spacing";
@import "../mixins/screen";

/*
    BORDERS
    Base:
      b = border
      br = border-radius
    Modifiers:
      a = all
      t = top
      r = right
      b = bottom
      l = left
      n = none
      -0 = border radius 0
      -1 = border radius 1st step in spacing scale
      -2 = border radius 2nd step in spacing scale
      -7 = border radius 7th step in spacing scale
      -50 = border radius 50%

      Media Query:
        sm_ = small and up
        md_  = medium and up
        lg_  = large and up
        xl_  = extra large and up
   */

.ba {
	border-style: solid !important;
	border-width: 1px !important;
}
.bt {
	border-top-style: solid !important;
	border-top-width: 1px !important;
}
.br {
	border-right-style: solid !important;
	border-right-width: 1px !important;
}
.bb {
	border-bottom-style: solid !important;
	border-bottom-width: 1px !important;
}
.bl {
	border-left-style: solid !important;
	border-left-width: 1px !important;
}
.bn {
	border-style: none !important;
	border-width: 0 !important;
}

.br-100 {
  border-radius: 100% !important;
}
.br-50 {
	border-radius: 50% !important;
}
.br-0 {
	border-radius: 0 !important;
}
.br-1 {
border-radius: $spacing-1 !important;
}
.br-2 {
	border-radius: $spacing-2 !important;
}
.br-3 {
	border-radius: $spacing-3 !important;
}
.br-4 {
	border-radius: $spacing-4 !important;
}
.br-5 {
	border-radius: $spacing-5 !important;
}
.btlr-1 {
  border-top-left-radius: $spacing-1 !important;
}

.btlr-2 {
  border-top-left-radius: $spacing-2 !important;
}

.btlr-3 {
  border-top-left-radius: $spacing-3 !important;
}

.btlr-4 {
  border-top-left-radius: $spacing-4 !important;
}

.btlr-5 {
  border-top-left-radius: $spacing-5 !important;
}

@include sm {
	.sm_ba {
		border-style: solid !important;
		border-width: 1px !important;
	}
	.sm_bt {
		border-top-style: solid !important;
		border-top-width: 1px !important;
	}
	.sm_br {
		border-right-style: solid !important;
		border-right-width: 1px !important;
	}
	.sm_bb {
		border-bottom-style: solid;
		border-bottom-width: 1px;
	}
	.sm_bl {
		border-left-style: solid !important;
		border-left-width: 1px !important;
	}
	.sm_bn {
		border-style: none !important;
		border-width: 0 !important;
	}
	.sm_br-50 {
		border-radius: 50% !important;
	}
	.sm_br-0 {
		border-radius: 0 !important;
	}
	.sm_br-1 {
	border-radius: $spacing-1 !important;
	}
	.sm_br-2 {
		border-radius: $spacing-2 !important;
	}
  .sm_br-3 {
    border-radius: $spacing-3 !important;
  }
  .sm_br-4 {
    border-radius: $spacing-4 !important;
  }
  .sm_br-5 {
    border-radius: $spacing-5 !important;
  }
  .sm_btlr-1 {
    border-top-left-radius: $spacing-1 !important;
  }
  
  .sm_btlr-2 {
    border-top-left-radius: $spacing-2 !important;
  }
  
  .sm_btlr-3 {
    border-top-left-radius: $spacing-3 !important;
  }
  
  .sm_btlr-4 {
    border-top-left-radius: $spacing-4 !important;
  }
  .sm_btlr-5 {
    border-top-left-radius: $spacing-5 !important;
  }
}

@include md {
	.md_ba {
		border-style: solid !important;
		border-width: 1px !important;
	}
	.md_bt {
		border-top-style: solid !important;
		border-top-width: 1px !important;
	}
	.md_br {
		border-right-style: solid !important;
		border-right-width: 1px !important;
	}
	.md_bb {
		border-bottom-style: solid !important;
		border-bottom-width: 1px !important;
	}
	.md_bl {
		border-left-style: solid !important;
		border-left-width: 1px !important;
	}
	.md_bn {
		border-style: none !important;
		border-width: 0 !important;
	}
	.md_br-50 {
		border-radius: 50% !important;
	}
	.md_br-0 {
		border-radius: 0 !important;
	}
	.md_br-1 {
	border-radius: $spacing-1 !important;
	}
	.md_br-2 {
		border-radius: $spacing-2 !important;
	}
  .md_br-3 {
    border-radius: $spacing-3 !important;
  }
  .md_br-4 {
    border-radius: $spacing-4 !important;
  }
  .md_br-5 {
    border-radius: $spacing-5 !important;
  }
  .md_btlr-1 {
    border-top-left-radius: $spacing-1 !important;
  }
  .md_btlr-2 {
    border-top-left-radius: $spacing-2 !important;
  }
  
  .md_btlr-3 {
    border-top-left-radius: $spacing-3 !important;
  }
  
  .md_btlr-4 {
    border-top-left-radius: $spacing-4 !important;
  }
  
  .md_btlr-5 {
    border-top-left-radius: $spacing-5 !important;
  }
}

@include lg {
	.lg_ba {
		border-style: solid !important;
		border-width: 1px !important;
	}
	.lg_bt {
		border-top-style: solid !important;
		border-top-width: 1px !important;
	}
	.lg_br {
		border-right-style: solid !important;
		border-right-width: 1px !important;
	}
	.lg_bb {
		border-bottom-style: solid !important;
		border-bottom-width: 1px !important;
	}
	.lg_bl {
		border-left-style: solid !important;
		border-left-width: 1px !important;
	}
	.lg_bn {
		border-style: none !important;
		border-width: 0 !important;
	}
	.lg_br-50 {
		border-radius: 50% !important;
	}
	.lg_br-0 {
		border-radius: 0 !important;
	}
	.lg_br-1 {
	border-radius: $spacing-1 !important;
	}
	.lg_br-2 {
		border-radius: $spacing-2 !important;
	}
  .lg_br-3 {
    border-radius: $spacing-3 !important;
  }
  .lg_br-4 {
    border-radius: $spacing-4 !important;
  }
  .lg_br-5 {
    border-radius: $spacing-5 !important;
  }
  .lg_btlr-1 {
    border-top-left-radius: $spacing-1 !important;
  }
  
  .lg_btlr-2 {
    border-top-left-radius: $spacing-2 !important;
  }
  
  .lg_btlr-3 {
    border-top-left-radius: $spacing-3 !important;
  }
  
  .lg_btlr-4 {
    border-top-left-radius: $spacing-4 !important;
  }
  .lg_btlr-5 {
    border-top-left-radius: $spacing-5 !important;
  }
}

@include xl {
	.xl_ba {
		border-style: solid !important;
		border-width: 1px !important;
	}
	.xl_bt {
		border-top-style: solid !important;
		border-top-width: 1px !important;
	}
	.xl_br-0 {
		border-radius: 0 !important;
	}
	.xl_br-1 {
	border-radius: $spacing-1 !important;
	}
	.xl_br-2 {
		border-radius: $spacing-2 !important;
	}
  .xl_br-3 {
    border-radius: $spacing-3 !important;
  }
  .xl_br-4 {
    border-radius: $spacing-4 !important;
  }
  .xl_br-5 {
    border-radius: $spacing-5 !important;
  }
  .xl_btlr-1 {
    border-top-left-radius: $spacing-1 !important;
  }
  
  .xl_btlr-2 {
    border-top-left-radius: $spacing-2 !important;
  }
  
  .xl_btlr-3 {
    border-top-left-radius: $spacing-3 !important;
  }
  
  .xl_btlr-4 {
    border-top-left-radius: $spacing-4 !important;
  }
  .xl_btlr-5 {
    border-top-left-radius: $spacing-5 !important;
  }
}

@include xxl {
	.xxl_ba {
		border-style: solid !important;
		border-width: 1px !important;
	}
	.xxl_bt {
		border-top-style: solid !important;
		border-top-width: 1px !important;
	}
	.xxl_br {
		border-right-style: solid !important;
		border-right-width: 1px !important;
	}
	.xxl_bb {
		border-bottom-style: solid !important;
		border-bottom-width: 1px !important;
	}
	.xxl_bl {
		border-left-style: solid !important;
		border-left-width: 1px !important;
	}
	.xxl_bn {
		border-style: none !important;
		border-width: 0 !important;
	}
	.xxl_br-50 {
		border-radius: 50% !important;
	}
	.xxl_br-0 {
		border-radius: 0 !important;
	}
	.xxl_br-1 {
	border-radius: $spacing-1 !important;
	}
	.xxl_br-2 {
		border-radius: $spacing-2 !important;
	}
  .xxl_br-3 {
    border-radius: $spacing-3 !important;
  }
  .xxl_br-4 {
    border-radius: $spacing-4 !important;
  }
  .xxl_br-5 {
    border-radius: $spacing-5 !important;
  }
  .xxl_btlr-1 {
    border-top-left-radius: $spacing-1 !important;
  }
  
  .xxl_btlr-2 {
    border-top-left-radius: $spacing-2 !important;
  }
  
  .xxl_btlr-3 {
    border-top-left-radius: $spacing-3 !important;
  }
  
  .xxl_btlr-4 {
    border-top-left-radius: $spacing-4 !important;
  }
  .xxl_btlr-5 {
    border-top-left-radius: $spacing-5 !important;
  }
}