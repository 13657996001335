@import "../variables/colors";
@import "../mixins/screen";

/*
    BORDERS

    Base:
      color-name - color
      bg = background
      b = border

    Modifiers:
      -color-name - color
      -color-inherit - string value of inherit
      -transparent - string value of transparent

      Media Query:
        sm_ = small and up
        md_  = medium and up
        lg_  = large and up
        xl_  = extra large and up
   */

$colors: (
  "white": $white,
  "gray-1": $gray-1,
  "gray-2": $gray-2,
  "gray-3": $gray-3,
  "gray-4": $gray-4,
  "gray-5": $gray-5,
  "gray-6": $gray-6,
  "gray-7": $gray-7,
  "gray-8": $gray-8,
  "gray-9": $gray-9,
  "teal-6": $teal-6,
  "teal-7": $teal-7,
  "green-6": $green-6,
  "red-1": $red-1,
  "color-inherit": inherit,
  "transparent": transparent,
);

@each $name, $color in $colors {
  .#{$name} {
    color: $color !important;
  }
  .bg-#{$name} {
    background-color: $color !important;
  }
  .b-#{$name} {
    border-color: $color !important;
  }
  .h_#{$name} {
    &:hover {
      color: $color !important;
    }
  }
}

@include sm {
  @each $name, $color in $colors {
    .sm_#{$name} {
      color: $color !important;
    }
    .sm_bg-#{$name} {
      background-color: $color !important;
    }
    .sm_b-#{$name} {
      border-color: $color !important;
    }
  }
}

@include md {
  @each $name, $color in $colors {
    .md_#{$name} {
      color: $color !important;
    }
    .md_bg-#{$name} {
      background-color: $color !important;
    }
    .md_b-#{$name} {
      border-color: $color !important;
    }
  }
}

@include lg {
  @each $name, $color in $colors {
    .lg_#{$name} {
      color: $color !important;
    }
    .lg_bg-#{$name} {
      background-color: $color !important;
    }
    .lg_b-#{$name} {
      border-color: $color !important;
    }
  }
}

@include xl {
  @each $name, $color in $colors {
    .xl_#{$name} {
      color: $color !important;
    }
    .xl_bg-#{$name} {
      background-color: $color !important;
    }
    .xl_b-#{$name} {
      border-color: $color !important;
    }
  }
}

@include xxl {
  @each $name, $color in $colors {
    .xxl_#{$name} {
      color: $color !important;
    }
    .xxl_bg-#{$name} {
      background-color: $color !important;
    }
    .xxl_b-#{$name} {
      border-color: $color !important;
    }
  }
}
