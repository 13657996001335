@import "../mixins/screen";

.flex {
  display: flex !important;
}
.inline-flex {
  display: inline-flex !important;
}

.vh {
  visibility: hidden !important;
}

.dn {
  display: none !important;
}
.di {
  display: inline !important;
}
.db {
  display: block !important;
}

.dib {
  display: inline-block !important;
}
.dit {
  display: inline-table !important;
}
.dt {
  display: table !important;
}
.dtc {
  display: table-cell !important;
}
.dt-row {
  display: table-row !important;
}
.dt-row-group {
  display: table-row-group !important;
}
.dt-column {
  display: table-column !important;
}
.dt-column-group {
  display: table-column-group !important;
}

/*
  This will set table to full width and then
  all cells will be equal width
*/
.dt-fixed {
  table-layout: fixed !important;
  width: 100% !important;
}

@include sm {
  .sm_flex {
    display: flex !important;
  }
  .sm_inline-flex {
    display: inline-flex !important;
  }
  .sm_dn {
    display: none !important;
  }
  .sm_di {
    display: inline !important;
  }
  .sm_db {
    display: block !important;
  }
  .sm_dib {
    display: inline-block !important;
  }
  .sm_dit {
    display: inline-table !important;
  }
  .sm_dt {
    display: table !important;
  }
  .sm_dtc {
    display: table-cell !important;
  }
  .sm_dt-row {
    display: table-row !important;
  }
  .sm_dt-row-group {
    display: table-row-group !important;
  }
  .sm_dt-column {
    display: table-column !important;
  }
  .sm_dt-column-group {
    display: table-column-group !important;
  }

  .sm_dt-fixed {
    table-layout: fixed !important;
    width: 100% !important;
  }
}

@include md {
  .md_flex {
    display: flex !important;
  }
  .md_inline-flex {
    display: inline-flex !important;
  }
  .md_dn {
    display: none !important;
  }
  .md_di {
    display: inline !important;
  }
  .md_db {
    display: block !important;
  }
  .md_dib {
    display: inline-block !important;
  }
  .md_dit {
    display: inline-table !important;
  }
  .md_dt {
    display: table !important;
  }
  .md_dtc {
    display: table-cell !important;
  }
  .md_dt-row {
    display: table-row !important;
  }
  .md_dt-row-group {
    display: table-row-group !important;
  }
  .md_dt-column {
    display: table-column !important;
  }
  .md_dt-column-group {
    display: table-column-group !important;
  }

  .md_dt-fixed {
    table-layout: fixed !important;
    width: 100% !important;
  }
}

@include lg {
  .lg_flex {
    display: flex !important;
  }
  .lg_inline-flex {
    display: inline-flex !important;
  }
  .lg_dn {
    display: none !important;
  }
  .lg_di {
    display: inline !important;
  }
  .lg_db {
    display: block !important;
  }
  .lg_dib {
    display: inline-block !important;
  }
  .lg_dit {
    display: inline-table !important;
  }
  .lg_dt {
    display: table !important;
  }
  .lg_dtc {
    display: table-cell !important;
  }
  .lg_dt-row {
    display: table-row !important;
  }
  .lg_dt-row-group {
    display: table-row-group !important;
  }
  .lg_dt-column {
    display: table-column !important;
  }
  .lg_dt-column-group {
    display: table-column-group !important;
  }

  .lg_dt-fixed {
    table-layout: fixed !important;
    width: 100% !important;
  }
}

@include xl {
  .xl_flex {
    display: flex !important;
  }
  .xl_inline-flex {
    display: inline-flex !important;
  }
  .xl_dn {
    display: none !important;
  }
  .xl_di {
    display: inline !important;
  }
  .xl_db {
    display: block !important;
  }
  .xl_dib {
    display: inline-block !important;
  }
  .xl_dit {
    display: inline-table !important;
  }
  .xl_dt {
    display: table !important;
  }
  .xl_dtc {
    display: table-cell !important;
  }
  .xl_dt-row {
    display: table-row !important;
  }
  .xl_dt-row-group {
    display: table-row-group !important;
  }
  .xl_dt-column {
    display: table-column !important;
  }
  .xl_dt-column-group {
    display: table-column-group !important;
  }

  .xl_dt-fixed {
    table-layout: fixed !important;
    width: 100%;
  }
}

@include xxl {
  .xxl_flex {
    display: flex !important;
  }
  .xxl_inline-flex {
    display: inline-flex !important;
  }
  .xxl_dn {
    display: none !important;
  }
  .xxl_di {
    display: inline !important;
  }
  .xxl_db {
    display: block !important;
  }
  .xxl_dib {
    display: inline-block !important;
  }
  .xxl_dit {
    display: inline-table !important;
  }
  .xxl_dt {
    display: table !important;
  }
  .xxl_dtc {
    display: table-cell !important;
  }
  .xxl_dt-row {
    display: table-row !important;
  }
  .xxl_dt-row-group {
    display: table-row-group !important;
  }
  .xxl_dt-column {
    display: table-column !important;
  }
  .xxl_dt-column-group {
    display: table-column-group !important;
  }

  .xxl_dt-fixed {
    table-layout: fixed !important;
    width: 100%;
  }
}
