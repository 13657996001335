$spacing-0: 0px;
$spacing-1: 4px;
$spacing-2: 8px;
$spacing-3: 12px;
$spacing-4: 16px;
$spacing-5: 24px;
$spacing-6: 32px;
$spacing-7: 40px;
$spacing-8: 48px;
$spacing-9: 64px;
$spacing-10: 80px;
$spacing-11: 100px;

$spacing: (
  "0": $spacing-0,
  "1": $spacing-1,
  "2": $spacing-2,
  "3": $spacing-3,
  "4": $spacing-4,
  "5": $spacing-5,
  "6": $spacing-6,
  "7": $spacing-7,
  "8": $spacing-8,
  "9": $spacing-9,
  "10": $spacing-10,
  "11": $spacing-11,
);
