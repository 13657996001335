@import "../variables/spacing";
@import "../mixins/screen";

/*
   WIDTHS

   Base:
     w = height
     max-w = max-width
     min-w = min-width

   Modifiers
     -1 = 1st step in height scale
     -2 = 2nd step in height scale
     -3 = 3rd step in height scale
     -4 = 4th step in height scale
     -5 = 5th step in height scale
     -6 = 6th step in height scale
     -7 = 7th step in height scale
     -8 = 8th step in height scale
     -9 = 9th step in height scale
     -10 = 10th step in height scale
     -20   = literal value 20%
     -33   = literal value 33.333333%
     -25   = literal value 25%
     -50   = literal value 50%
     -66   = literal value 66.666666%
     -75   = literal value 75%
     -80   = literal value 80%
     -100  = literal value 100%
     -auto = string value of auto
     -inherit = string value of inherit
     -sidebar = value of sidebar

     Media Query:
       sm_ = small and up
       md_  = medium and up
       lg_  = large and up
       xl_  = extra large and up
  */

$sidebar: 498px;

$width: (
  "0": $spacing-0,
  "1": $spacing-1,
  "2": $spacing-2,
  "3": $spacing-3,
  "4": $spacing-4,
  "5": $spacing-5,
  "6": $spacing-6,
  "7": $spacing-7,
  "8": $spacing-8,
  "9": $spacing-9,
  "10": $spacing-10,
  "11": $spacing-11,
  "15": 15%,
  "20": 20%,
  "25": 25%,
  "30": 30%,
  "33": 33.333333%,
  "40": 40%,
  "45": 45%,
  "50": 50%,
  "55": 55%,
  "60": 60%,
  "66": 66.666666%,
  "70": 70%,
  "75": 75%,
  "80": 80%,
  "90": 90%,
  "95": 95%,
  "100": 100%,
  "auto": auto,
  "inherit": inherit,
  "sidebar": $sidebar,
  "100vw": 100vw,
  "46vw": 46vw,
  "47vw": 47vw,
);

@each $name, $size in $width {
  .w-#{$name} {
    width: $size !important;
  }
  .max-w-#{$name} {
    max-width: $size !important;
  }
  .min-w-#{$name} {
    min-width: $size !important;
  }
}

@include sm {
  @each $name, $size in $width {
    .sm_w-#{$name} {
      width: $size !important;
    }
    .sm_max-w-#{$name} {
      max-width: $size !important;
    }
    .sm_min-w-#{$name} {
      min-width: $size !important;
    }
  }
}

@include md {
  @each $name, $size in $width {
    .md_w-#{$name} {
      width: $size !important;
    }
    .md_max-w-#{$name} {
      max-width: $size !important;
    }
    .md_min-w-#{$name} {
      min-width: $size !important;
    }
  }
}

@include lg {
  @each $name, $size in $width {
    .lg_w-#{$name} {
      width: $size !important;
    }
    .lg_max-w-#{$name} {
      max-width: $size !important;
    }
    .lg_min-w-#{$name} {
      min-width: $size !important;
    }
  }
}

@include xl {
  @each $name, $size in $width {
    .xl_w-#{$name} {
      width: $size !important;
    }
    .xl_max-w-#{$name} {
      max-width: $size !important;
    }
    .xl_min-w-#{$name} {
      min-width: $size !important;
    }
  }
}

@include xxl {
  @each $name, $size in $width {
    .xxl_w-#{$name} {
      width: $size !important;
    }
    .xxl_max-w-#{$name} {
      max-width: $size !important;
    }
    .xxl_min-w-#{$name} {
      min-width: $size !important;
    }
  }
}
