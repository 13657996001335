@import "../variables/spacing";
@import "../mixins/screen";

/*
   COORDINATES
   Use in combination with the position module.

   Base:
     top
     bottom
     right
     left

   Modifiers:
     -0  = literal value 0
     -1 = 1st step in spacing scale
     -2 = 2nd step in spacing scale
     -3 = 3rd step in spacing scale
     -4 = 4th step in spacing scale
     -5 = 5th step in spacing scale
     -6 = 6th step in spacing scale
     --1 = negative 1st step in spacing scale
     --2 = negative 2nd step in spacing scale
     --3 = negative 3rd step in spacing scale
     --4 = negative 4th step in spacing scale
     --5 = negative 5th step in spacing scale
     --6 = negative 6th step in spacing scale

   Media Query:
     sm_ = small and up
     md_  = medium and up
     lg_  = large and up
     xl_  = extra large and up
*/

$coordiates: (
	"0": $spacing-0,
	"1": $spacing-1,
	"2": $spacing-2,
	"3": $spacing-3,
	"4": $spacing-4,
	"5": $spacing-5,
	"6": $spacing-6,
);

@each $name, $spacing in $coordiates {
	.top-#{$name} {
		top: $spacing !important;
	}
	.right-#{$name} {
		right: $spacing !important;
	}
	.bottom-#{$name} {
		bottom: $spacing !important;
	}
	.left-#{$name} {
		left: $spacing !important;
	}
	.top--#{$name} {
		top: -$spacing !important;
	}
	.right--#{$name} {
		right: -$spacing !important;
	}
	.bottom--#{$name} {
		bottom: -$spacing !important;
	}
	.left--#{$name} {
		left: -$spacing !important;
	}
  .absolute-fill {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@include sm {
	@each $name, $spacing in $coordiates {
		.sm_top-#{$name} {
			top: $spacing !important;
		}
		.sm_right-#{$name} {
			right: $spacing !important;
		}
		.sm_bottom-#{$name} {
			bottom: $spacing !important;
		}
		.sm_left-#{$name} {
			left: $spacing !important;
		}
		.sm_top--#{$name} {
			top: -$spacing !important;
		}
		.sm_right--#{$name} {
			right: -$spacing !important;
		}
		.sm_bottom--#{$name} {
			bottom: -$spacing !important;
		}
		.sm_left--#{$name} {
			left: -$spacing !important;
		}
	}
}

@include md {
	@each $name, $spacing in $coordiates {
		.md_top-#{$name} {
			top: $spacing !important;
		}
		.md_right-#{$name} {
			right: $spacing !important;
		}
		.md_bottom-#{$name} {
			bottom: $spacing !important;
		}
		.md_left-#{$name} {
			left: $spacing !important;
		}
		.md_top--#{$name} {
			top: -$spacing !important;
		}
		.md_right--#{$name} {
			right: -$spacing !important;
		}
		.md_bottom--#{$name} {
			bottom: -$spacing !important;
		}
		.md_left--#{$name} {
			left: -$spacing !important;
		}
	}
}

@include lg {
	@each $name, $spacing in $coordiates {
		.lg_top-#{$name} {
			top: $spacing !important;
		}
		.lg_right-#{$name} {
			right: $spacing !important;
		}
		.lg_bottom-#{$name} {
			bottom: $spacing !important;
		}
		.lg_left-#{$name} {
			left: $spacing !important;
		}
		.lg_top--#{$name} {
			top: -$spacing !important;
		}
		.lg_right--#{$name} {
			right: -$spacing !important;
		}
		.lg_bottom--#{$name} {
			bottom: -$spacing !important;
		}
		.lg_left--#{$name} {
			left: -$spacing !important;
		}
	}
}

@include xl {
	@each $name, $spacing in $coordiates {
		.xl_top-#{$name} {
			top: $spacing !important;
		}
		.xl_right-#{$name} {
			right: $spacing !important;
		}
		.xl_bottom-#{$name} {
			bottom: $spacing !important;
		}
		.xl_left-#{$name} {
			left: $spacing !important;
		}
		.xl_top--#{$name} {
			top: -$spacing !important;
		}
		.xl_right--#{$name} {
			right: -$spacing !important;
		}
		.xl_bottom--#{$name} {
			bottom: -$spacing !important;
		}
		.xl_left--#{$name} {
			left: -$spacing !important;
		}
	}
}

@include xxl {
	@each $name, $spacing in $coordiates {
		.xxl_top-#{$name} {
			top: $spacing !important;
		}
		.xxl_right-#{$name} {
			right: $spacing !important;
		}
		.xxl_bottom-#{$name} {
			bottom: $spacing !important;
		}
		.xxl_left-#{$name} {
			left: $spacing !important;
		}
		.xxl_top--#{$name} {
			top: -$spacing !important;
		}
		.xxl_right--#{$name} {
			right: -$spacing !important;
		}
		.xxl_bottom--#{$name} {
			bottom: -$spacing !important;
		}
		.xxl_left--#{$name} {
			left: -$spacing !important;
		}
	}
}
