@import "../mixins/screen";

/*
  Z-INDEX

  Base
    z = z-index

  Modifiers
    -0 = literal value 0
    -1 = literal value 1
    -2 = literal value 2
    -3 = literal value 3
    -4 = literal value 4
    -5 = literal value 5
    -99 = literal value 999
    -999 = literal value 999
    -9999 = literal value 9999
    -max = largest accepted z-index value as integer
    -inherit = string value inherit
    -initial = string value initial
    -unset = string value unset

  Articles:
    https://philipwalton.com/articles/what-no-one-told-you-about-z-index/
*/

$z-index: (
	"0": 0,
	"1": 1,
	"2": 2,
	"3": 3,
	"4": 4,
	"5": 5,
	"99": 99,
	"999": 999,
	"9999": 9999,
	"max": 2147483647,
	"inherit": inherit,
	"initial": initial,
	"unset": unset,
);

@each $name, $number in $z-index {
	.z-#{$name} {
		z-index: $number !important;
	}
}

@include sm {
	@each $name, $number in $z-index {
		.sm_z-#{$name} {
			z-index: $number !important;
		}
	}
}

@include md {
	@each $name, $number in $z-index {
		.md_z-#{$name} {
			z-index: $number !important;
		}
	}
}

@include lg {
	@each $name, $number in $z-index {
		.lg_z-#{$name} {
			z-index: $number !important;
		}
	}
}

@include xl {
	@each $name, $number in $z-index {
		.xl_z-#{$name} {
			z-index: $number !important;
		}
	}
}

@include xxl {
	@each $name, $number in $z-index {
		.xxl_z-#{$name} {
			z-index: $number !important;
		}
	}
}
