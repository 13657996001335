@import "../variables/spacing";
@import "../mixins/screen";

/*
   PADDING
   A 10 step spacing scale.

   Base:
     p = padding

   Modifiers:
     a = all
     h = horizontal
     v = vertical
     t = top
     r = right
     b = bottom
     l = left
     -0 = none
     -1 = 1st step in spacing scale
     -2 = 2nd step in spacing scale
     -3 = 3rd step in spacing scale
     -4 = 4th step in spacing scale
     -5 = 5th step in spacing scale
     -6 = 6th step in spacing scale
     -7 = 7th step in spacing scale
     -8 = 8th step in spacing scale
		 -9 = 9th step in spacing scale
     -10 = 10th step in spacing scale
		 
	 Media Query:
     sm_ = small and up
     md_  = medium and up
     lg_  = large and up
     xl_  = extra large and up
*/

$padding: (
	"0": $spacing-0,
	"1": $spacing-1,
	"2": $spacing-2,
	"3": $spacing-3,
	"4": $spacing-4,
	"5": $spacing-5,
	"6": $spacing-6,
	"7": $spacing-7,
	"8": $spacing-8,
  "9": $spacing-9,
	"10": $spacing-10,
  "6vw": 6vw,
  "11vw": 11vw,
  "100": 100%
);

@each $name, $spacing in $padding {
	.pa-#{$name} {
		padding: $spacing !important;
	}
	.pl-#{$name} {
		padding-left: $spacing !important;
	}
	.pr-#{$name} {
		padding-right: $spacing !important;
	}
	.pb-#{$name} {
		padding-bottom: $spacing !important;
	}
	.pt-#{$name} {
		padding-top: $spacing !important;
	}
	.pv-#{$name} {
		padding-top: $spacing !important;
		padding-bottom: $spacing !important;
	}
	.ph-#{$name} {
		padding-left: $spacing !important;
		padding-right: $spacing !important;
	}
}

@include sm {
	@each $name, $spacing in $padding {
		.sm_pa-#{$name} {
			padding: $spacing !important;
		}
		.sm_pl-#{$name} {
			padding-left: $spacing !important;
		}
		.sm_pr-#{$name} {
			padding-right: $spacing !important;
		}
		.sm_pb-#{$name} {
			padding-bottom: $spacing !important;
		}
		.sm_pt-#{$name} {
			padding-top: $spacing !important;
		}
		.sm_pv-#{$name} {
			padding-top: $spacing !important;
			padding-bottom: $spacing !important;
		}
		.sm_ph-#{$name} {
			padding-left: $spacing !important;
			padding-right: $spacing !important;
		}
	}
}

@include md {
	@each $name, $spacing in $padding {
		.md_pa-#{$name} {
			padding: $spacing !important;
		}
		.md_pl-#{$name} {
			padding-left: $spacing !important;
		}
		.md_pr-#{$name} {
			padding-right: $spacing !important;
		}
		.md_pb-#{$name} {
			padding-bottom: $spacing !important;
		}
		.md_pt-#{$name} {
			padding-top: $spacing !important;
		}
		.md_pv-#{$name} {
			padding-top: $spacing !important;
			padding-bottom: $spacing !important;
		}
		.md_ph-#{$name} {
			padding-left: $spacing !important;
			padding-right: $spacing !important;
		}
	}
}

@include lg {
	@each $name, $spacing in $padding {
		.lg_pa-#{$name} {
			padding: $spacing !important;
		}
		.lg_pl-#{$name} {
			padding-left: $spacing !important;
		}
		.lg_pr-#{$name} {
			padding-right: $spacing !important;
		}
		.lg_pb-#{$name} {
			padding-bottom: $spacing !important;
		}
		.lg_pt-#{$name} {
			padding-top: $spacing !important;
		}
		.lg_pv-#{$name} {
			padding-top: $spacing !important;
			padding-bottom: $spacing !important;
		}
		.lg_ph-#{$name} {
			padding-left: $spacing !important;
			padding-right: $spacing !important;
		}
	}
}

@include xl {
	@each $name, $spacing in $padding {
		.xl_pa-#{$name} {
			padding: $spacing !important;
		}
		.xl_pl-#{$name} {
			padding-left: $spacing !important;
		}
		.xl_pr-#{$name} {
			padding-right: $spacing !important;
		}
		.xl_pb-#{$name} {
			padding-bottom: $spacing !important;
		}
		.xl_pt-#{$name} {
			padding-top: $spacing !important;
		}
		.xl_pv-#{$name} {
			padding-top: $spacing !important;
			padding-bottom: $spacing !important;
		}
		.xl_ph-#{$name} {
			padding-left: $spacing !important;
			padding-right: $spacing !important;
		}
	}
}

@include xxl {
	@each $name, $spacing in $padding {
		.xxl_pa-#{$name} {
			padding: $spacing !important;
		}
		.xxl_pl-#{$name} {
			padding-left: $spacing !important;
		}
		.xxl_pr-#{$name} {
			padding-right: $spacing !important;
		}
		.xxl_pb-#{$name} {
			padding-bottom: $spacing !important;
		}
		.xxl_pt-#{$name} {
			padding-top: $spacing !important;
		}
		.xxl_pv-#{$name} {
			padding-top: $spacing !important;
			padding-bottom: $spacing !important;
		}
		.xxl_ph-#{$name} {
			padding-left: $spacing !important;
			padding-right: $spacing !important;
		}
	}
}
