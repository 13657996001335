@import "../variables/spacing";
@import "../mixins/screen";

/*
   MARGIN
   A 10 step spacing scale.

   Base:
     m = padding

   Modifiers:
     a = all
     h = horizontal
     v = vertical
     t = top
     r = right
     b = bottom
     l = left
     -0 = none
     -1 = 1st step in spacing scale
     -2 = 2nd step in spacing scale
     -3 = 3rd step in spacing scale
     -4 = 4th step in spacing scale
     -5 = 5th step in spacing scale
     -6 = 6th step in spacing scale
     -7 = 7th step in spacing scale
     -8 = 8th step in spacing scale
		 -9 = 9th step in spacing scale
     -10 = 10th step in spacing scale
		 --1 = negative 1st step in spacing scale
		 --2 = negative 2nd step in spacing scale
		 --3 = negative 3rd step in spacing scale
		 --4 = negative 4th step in spacing scale
		 --5 = negative 5th step in spacing scale
		 --6 = negative 6th step in spacing scale
		 --7 = negative 7th step in spacing scale
		 --8 = negative 8th step in spacing scale
		 --9 = negative 9th step in spacing scale
		 --10 = negative 10th step in spacing scale

	 Media Query:
     sm_ = small and up
     md_  = medium and up
     lg_  = large and up
     xl_  = extra large and up
*/

$margin: (
	"0": $spacing-0,
	"1": $spacing-1,
	"2": $spacing-2,
	"3": $spacing-3,
	"4": $spacing-4,
	"5": $spacing-5,
	"6": $spacing-6,
	"7": $spacing-7,
	"8": $spacing-8,
  "9": $spacing-9,
	"10": $spacing-10,
  "5vh": 5vh,
	"auto": auto
);

@each $name, $spacing in $margin {
	.ma-#{$name} {
		margin: $spacing !important;
	}
	.ml-#{$name} {
		margin-left: $spacing !important;
	}
	.mr-#{$name} {
		margin-right: $spacing !important;
	}
	.mb-#{$name} {
		margin-bottom: $spacing !important;
	}
	.mt-#{$name} {
		margin-top: $spacing !important;
	}
	.mv-#{$name} {
		margin-top: $spacing !important;
		margin-bottom: $spacing !important;
	}
	.mh-#{$name} {
		margin-left: $spacing !important;
		margin-right: $spacing !important;
	}
	.ml--#{$name} {
		margin-left: -$spacing !important;
	}
	.mr--#{$name} {
		margin-right: -$spacing !important;
	}
	.mb--#{$name} {
		margin-bottom: -$spacing !important;
	}
	.mt--#{$name} {
		margin-top: -$spacing !important;
	}
}

@include sm {
	@each $name, $spacing in $margin {
		.sm_ma-#{$name} {
			margin: $spacing !important;
		}
		.sm_ml-#{$name} {
			margin-left: $spacing !important;
		}
		.sm_mr-#{$name} {
			margin-right: $spacing !important;
		}
		.sm_mb-#{$name} {
			margin-bottom: $spacing !important;
		}
		.sm_mt-#{$name} {
			margin-top: $spacing !important;
		}
		.sm_mv-#{$name} {
			margin-top: $spacing !important;
			margin-bottom: $spacing !important;
		}
		.sm_mh-#{$name} {
			margin-left: $spacing !important;
			margin-right: $spacing !important;
		}
		.sm_ml--#{$name} {
			margin-left: -$spacing !important;
		}
		.sm_mr--#{$name} {
			margin-right: -$spacing !important;
		}
		.sm_mb--#{$name} {
			margin-bottom: -$spacing !important;
		}
		.sm_mt--#{$name} {
			margin-top: -$spacing !important;
		}
	}
}

@include md {
	@each $name, $spacing in $margin {
		.md_ma-#{$name} {
			margin: $spacing !important;
		}
		.md_ml-#{$name} {
			margin-left: $spacing !important;
		}
		.md_mr-#{$name} {
			margin-right: $spacing !important;
		}
		.md_mb-#{$name} {
			margin-bottom: $spacing !important;
		}
		.md_mt-#{$name} {
			margin-top: $spacing !important;
		}
		.md_mv-#{$name} {
			margin-top: $spacing !important;
			margin-bottom: $spacing !important;
		}
		.md_mh-#{$name} {
			margin-left: $spacing !important;
			margin-right: $spacing !important;
		}
		.md_ml--#{$name} {
			margin-left: -$spacing !important;
		}
		.md_mr--#{$name} {
			margin-right: -$spacing !important;
		}
		.md_mb--#{$name} {
			margin-bottom: -$spacing !important;
		}
		.md_mt--#{$name} {
			margin-top: -$spacing !important;
		}
	}
}

@include lg {
	@each $name, $spacing in $margin {
		.lg_ma-#{$name} {
			margin: $spacing !important;
		}
		.lg_ml-#{$name} {
			margin-left: $spacing !important;
		}
		.lg_mr-#{$name} {
			margin-right: $spacing !important;
		}
		.lg_mb-#{$name} {
			margin-bottom: $spacing !important;
		}
		.lg_mt-#{$name} {
			margin-top: $spacing !important;
		}
		.lg_mv-#{$name} {
			margin-top: $spacing !important;
			margin-bottom: $spacing !important;
		}
		.lg_mh-#{$name} {
			margin-left: $spacing !important;
			margin-right: $spacing !important;
		}
		.lg_ml--#{$name} {
			margin-left: -$spacing !important;
		}
		.lg_mr--#{$name} {
			margin-right: -$spacing !important;
		}
		.lg_mb--#{$name} {
			margin-bottom: -$spacing !important;
		}
		.lg_mt--#{$name} {
			margin-top: -$spacing !important;
		}
	}
}

@include xl {
	@each $name, $spacing in $margin {
		.xl_ma-#{$name} {
			margin: $spacing !important;
		}
		.xl_ml-#{$name} {
			margin-left: $spacing !important;
		}
		.xl_mr-#{$name} {
			margin-right: $spacing !important;
		}
		.xl_mb-#{$name} {
			margin-bottom: $spacing !important;
		}
		.xl_mt-#{$name} {
			margin-top: $spacing !important;
		}
		.xl_mv-#{$name} {
			margin-top: $spacing !important;
			margin-bottom: $spacing !important;
		}
		.xl_mh-#{$name} {
			margin-left: $spacing !important;
			margin-right: $spacing !important;
		}
		.xl_ml--#{$name} {
			margin-left: -$spacing !important;
		}
		.xl_mr--#{$name} {
			margin-right: -$spacing !important;
		}
		.xl_mb--#{$name} {
			margin-bottom: -$spacing !important;
		}
		.xl_mt--#{$name} {
			margin-top: -$spacing !important;
		}
	}
}

@include xxl {
	@each $name, $spacing in $margin {
		.xxl_ma-#{$name} {
			margin: $spacing !important;
		}
		.xxl_ml-#{$name} {
			margin-left: $spacing !important;
		}
		.xxl_mr-#{$name} {
			margin-right: $spacing !important;
		}
		.xxl_mb-#{$name} {
			margin-bottom: $spacing !important;
		}
		.xxl_mt-#{$name} {
			margin-top: $spacing !important;
		}
		.xxl_mv-#{$name} {
			margin-top: $spacing !important;
			margin-bottom: $spacing !important;
		}
		.xxl_mh-#{$name} {
			margin-left: $spacing !important;
			margin-right: $spacing !important;
		}
		.xxl_ml--#{$name} {
			margin-left: -$spacing !important;
		}
		.xxl_mr--#{$name} {
			margin-right: -$spacing !important;
		}
		.xxl_mb--#{$name} {
			margin-bottom: -$spacing !important;
		}
		.xl_mt--#{$name} {
			margin-top: -$spacing !important;
		}
	}
}
