@import "../variables/typography";
@import "../mixins/screen";

/* Truncate */
@mixin truncate($overflow: ellipsis) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: $overflow;
}

.usn {
  user-select: none !important;
}

.tl {
  text-align: left !important;
}
.tr {
  text-align: right !important;
}
.tc {
  text-align: center !important;
}
.tj {
  text-align: justify !important;
}

.strike {
  text-decoration: line-through !important;
}
.underline {
  text-decoration: underline !important;
}

.tdn {
  text-decoration: none !important;
}

.no-underline {
  text-decoration: none !important;
}

.ttc {
  text-transform: capitalize !important;
}
.ttl {
  text-transform: lowercase !important;
}
.ttu {
  text-transform: uppercase !important;
}
.ttn {
  text-transform: none !important;
}

.fwn {
  font-weight: $fontWeight-normal !important;
}
.fwb {
  font-weight: $fontWeight-bold !important;
}

.fwt {
  font-weight: $fontWeight-thick !important;
}

.fsi {
  font-style: italic !important;
}
.fsn {
  font-style: normal !important;
}

.break-word {
  word-break: break-word;
}

$font-size: (
  "1": $fontSize-1,
  "2": $fontSize-2,
  "3": $fontSize-3,
  "4": $fontSize-4,
  "5": $fontSize-5,
  "6": $fontSize-6,
  "7": $fontSize-7,
  "8": $fontSize-8,
  "9": $fontSize-9,
  "10": $fontSize-10,
  "11": $font-size-11,
  "12": $font-size-12,
  "13": $font-size-13,
  "14": $font-size-14,
  "inherit": inherit,
);

@each $name, $size in $font-size {
  .f-#{$name} {
    font-size: $size !important;
  }
}

$line-height: (
  "1": $lineheight-1,
  "2": $lineheight-2,
  "3": $lineheight-3,
  "4": $lineheight-4,
  "5": $lineheight-5,
  "6": $lineheight-6,
);

@each $name, $size in $line-height {
  .lh-#{$name} {
    line-height: $size !important;
  }
}

@include sm {
  .sm_tl {
    text-align: left !important;
  }
  .sm_tr {
    text-align: right !important;
  }
  .sm_tc {
    text-align: center !important;
  }
  .sm_tj {
    text-align: justify !important;
  }

  .sm_strike {
    text-decoration: line-through !important;
  }
  .sm_underline {
    text-decoration: underline !important;
  }
  .sm_no-underline {
    text-decoration: none !important;
  }

  .sm_ttc {
    text-transform: capitalize !important;
  }
  .sm_ttl {
    text-transform: lowercase !important;
  }
  .sm_ttu {
    text-transform: uppercase !important;
  }
  .sm_ttn {
    text-transform: none !important;
  }

  .sm_fwn {
    font-weight: $fontWeight-normal !important;
  }
  .sm_fwb {
    font-weight: $fontWeight-bold !important;
  }

  .sm_fsi {
    font-style: italic;
  }
  .sm_fsn {
    font-style: normal;
  }

  .sm_break-word {
    word-break: break-word;
  }

  .sm_truncate {
    @include truncate;
  }

  @each $name, $size in $font-size {
    .sm_f-#{$name} {
      font-size: $size !important;
    }
  }
}

@include md {
  .md_tl {
    text-align: left !important;
  }
  .md_tr {
    text-align: right !important;
  }
  .md_tc {
    text-align: center !important;
  }
  .md_tj {
    text-align: justify !important;
  }

  .md_strike {
    text-decoration: line-through !important;
  }
  .md_underline {
    text-decoration: underline !important;
  }
  .md_no-underline {
    text-decoration: none !important;
  }

  .md_ttc {
    text-transform: capitalize !important;
  }
  .md_ttl {
    text-transform: lowercase !important;
  }
  .md_ttu {
    text-transform: uppercase !important;
  }
  .md_ttn {
    text-transform: none !important;
  }

  @each $name, $size in $font-size {
    .md_f-#{$name} {
      font-size: $size !important;
    }
  }

  .md_fwn {
    font-weight: $fontWeight-normal !important;
  }
  .md_fwb {
    font-weight: $fontWeight-bold !important;
  }

  .md_fsi {
    font-style: italic;
  }
  .md_fsn {
    font-style: normal;
  }

  .md_break-word {
    word-break: break-word;
  }

  .md_truncate {
    @include truncate;
  }
}

@include lg {
  .lg_tl {
    text-align: left !important;
  }
  .lg_tr {
    text-align: right !important;
  }
  .lg_tc {
    text-align: center !important;
  }
  .lg_tj {
    text-align: justify !important;
  }

  .lg_strike {
    text-decoration: line-through !important;
  }
  .lg_underline {
    text-decoration: underline !important;
  }
  .lg_no-underline {
    text-decoration: none !important;
  }

  .lg_ttc {
    text-transform: capitalize !important;
  }
  .lg_ttl {
    text-transform: lowercase !important;
  }
  .lg_ttu {
    text-transform: uppercase !important;
  }
  .lg_ttn {
    text-transform: none !important;
  }

  .lg_fwn {
    font-weight: $fontWeight-normal !important;
  }
  .lg_fwb {
    font-weight: $fontWeight-bold !important;
  }

  .lg_fsi {
    font-style: italic;
  }
  .lg_fsn {
    font-style: normal;
  }

  .lg_break-word {
    word-break: break-word;
  }

  .lg_truncate {
    @include truncate;
  }

  @each $name, $size in $font-size {
    .lg_f-#{$name} {
      font-size: $size !important;
    }
  }
}

@include xl {
  .xl_tl {
    text-align: left !important;
  }
  .xl_tr {
    text-align: right !important;
  }
  .xl_tc {
    text-align: center !important;
  }
  .xl_tj {
    text-align: justify !important;
  }

  .xl_strike {
    text-decoration: line-through !important;
  }
  .xl_underline {
    text-decoration: underline !important;
  }
  .xl_no-underline {
    text-decoration: none !important;
  }

  .xl_ttc {
    text-transform: capitalize !important;
  }
  .xl_ttl {
    text-transform: lowercase !important;
  }
  .xl_ttu {
    text-transform: uppercase !important;
  }
  .xl_ttn {
    text-transform: none !important;
  }

  @each $name, $size in $font-size {
    .xl_f-#{$name} {
      font-size: $size !important;
    }
  }

  .xl_fwn {
    font-weight: $fontWeight-normal !important;
  }
  .xl_fwb {
    font-weight: $fontWeight-bold !important;
  }

  .xl_fsi {
    font-style: italic;
  }
  .xl_fsn {
    font-style: normal;
  }

  .xl_break-word {
    word-break: break-word;
  }

  .xl_truncate {
    @include truncate;
  }
}

@include xxl {
  .xxl_tl {
    text-align: left !important;
  }
  .xxl_tr {
    text-align: right !important;
  }
  .xxl_tc {
    text-align: center !important;
  }
  .xxl_tj {
    text-align: justify !important;
  }

  .xxl_strike {
    text-decoration: line-through !important;
  }
  .xxl_underline {
    text-decoration: underline !important;
  }
  .xxl_no-underline {
    text-decoration: none !important;
  }

  .xxl_ttc {
    text-transform: capitalize !important;
  }
  .xxl_ttl {
    text-transform: lowercase !important;
  }
  .xl_ttu {
    text-transform: uppercase !important;
  }
  .xxl_ttn {
    text-transform: none !important;
  }

  @each $name, $size in $font-size {
    .xxl_f-#{$name} {
      font-size: $size !important;
    }
  }

  .xxl_fwn {
    font-weight: $fontWeight-normal !important;
  }
  .xxl_fwb {
    font-weight: $fontWeight-bold !important;
  }

  .xxl_fsi {
    font-style: italic;
  }
  .xxl_fsn {
    font-style: normal;
  }

  .xxl_break-word {
    word-break: break-word;
  }

  .xxl_truncate {
    @include truncate;
  }
}


