@import "../variables/spacing";
@import "../mixins/screen";

/*
   HEIGHTS

   Base:
     h = height
     max-h = max-height
     min-h = min-height

   Modifiers
     -1 = 1st step in height scale
     -2 = 2nd step in height scale
     -3 = 3rd step in height scale
     -4 = 4th step in height scale
     -5 = 5th step in height scale
     -6 = 6th step in height scale
     -7 = 7th step in height scale
     -8 = 8th step in height scale
     -9 = 9th step in height scale
     -10 = 10th step in height scale
     -25   = literal value 25%
     -50   = literal value 50%
     -75   = literal value 75%
     -100  = literal value 100%
     -auto = string value of auto
     -inherit = string value of inherit

     Media Query:
       sm_ = small and up
       md_  = medium and up
       lg_  = large and up
       xl_  = extra large
       xxl_ = huge
  */

$height: (
  "0": $spacing-0,
  "1": $spacing-1,
  "2": $spacing-2,
  "3": $spacing-3,
  "4": $spacing-4,
  "5": $spacing-5,
  "6": $spacing-6,
  "7": $spacing-7,
  "8": $spacing-8,
  "9": $spacing-9,
  "10": $spacing-10,
  "11": $spacing-11,
  "20": 20%,
  "25": 25%,
  "30": 30%,
  "33": 33.333333%,
  "40": 40%,
  "50": 50%,
  "60": 60%,
  "66": 66.666666%,
  "70": 70%,
  "75": 75%,
  "80": 80%,
  "90": 90%,
  "95": 95%,
  "100": 100%,
  "50vh": 50vh,
  "60vh": 60vh,
  "70vh": 70vh,
  "80vh": 80vh,
  "100vh": 100vh,
  "inherit": inherit,
  "auto": auto,
);

@each $name, $size in $height {
  .h-#{$name} {
    height: $size !important;
  }
  .max-h-#{$name} {
    max-height: $size !important;
  }
  .min-h-#{$name} {
    min-height: $size !important;
  }
}

@include sm {
  @each $name, $size in $height {
    .sm_h-#{$name} {
      height: $size !important;
    }
    .sm_max-h-#{$name} {
      max-height: $size !important;
    }
    .sm_min-h-#{$name} {
      min-height: $size !important;
    }
  }
}

@include md {
  @each $name, $size in $height {
    .md_h-#{$name} {
      height: $size !important;
    }
    .md_max-h-#{$name} {
      max-height: $size !important;
    }
    .md_min-h-#{$name} {
      min-height: $size !important;
    }
  }
}

@include lg {
  @each $name, $size in $height {
    .lg_h-#{$name} {
      height: $size !important;
    }
    .lg_max-h-#{$name} {
      max-height: $size !important;
    }
    .lg_min-h-#{$name} {
      min-height: $size !important;
    }
  }
}

@include xl {
  @each $name, $size in $height {
    .xl_h-#{$name} {
      height: $size !important;
    }
    .xl_max-h-#{$name} {
      max-height: $size !important;
    }
    .xl_min-h-#{$name} {
      min-height: $size !important;
    }
  }
}

@include xxl {
  @each $name, $size in $height {
    .xxl_h-#{$name} {
      height: $size !important;
    }
    .xxl_max-h-#{$name} {
      max-height: $size !important;
    }
    .xxl_min-h-#{$name} {
      min-height: $size !important;
    }
  }
}
